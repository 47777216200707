<template>
  <div>
    <v-card class="elevation-0">
      <v-container>
        <div class="mt-md-16 mt-8 mb-4 text-h5 font-weight-bold">
          <v-btn @click="back" class="me-4" large color="orange" icon><v-icon large class="mr-1">mdi-arrow-left</v-icon> Back</v-btn>
        </div>
        <div class="mb-10 text-h5 font-weight-bold">
          PILIH TIPE AKUN PENGGUNA
        </div>
      <v-row>
        <v-col v-for="(item, index) in itemsTypeAccount" :key="index" class="px-2" cols="12" sm="12" md="6">
          <v-hover
              v-slot="{ hover }"
          >
          <v-card @click="toRegistration(item)" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"  :color="`${item.color} lighten-3`" class="pointer py-10 pl-6" min-height="160px" :disabled="item.disabled">
            <v-row align="center" justify="center" class="d-flex" >
              <v-row>
                <v-col cols="12" md="2">
                  <v-avatar :color="`${item.color} darken-4`" size="60" class="elevation-4"><v-icon color="white" large>{{item.icon}}</v-icon></v-avatar>
                </v-col>
                <v-col cols="12" md="7">
                  <div class="text-left">
                    <div class="headline font-weight-bold white--text">{{item.description}}</div>
                    <div class="caption">{{item.notes}}</div>
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-card>
          </v-hover>
        </v-col>
      </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "SelectAccountTypeView",
  components: {

  },
  data() {
    return {
      itemsTypeAccount:[
        {id:1, description:'ASN Kota Palangka Raya', notes:'Untuk ASN atau Pegawai di Lingkungan Kota Palangka Raya', color:"amber", icon:'mdi-account-tie'},
        {id:2, description:'ASN Luar Daerah', notes:'Untuk ASN Luar Daerah yang ingin mengajukan pindah ke dalam Lingkungan Pemerintah Kota Palangkaraya', color:"teal", icon:'mdi-account-convert'},
        // {id:3, description:'Forum Jakonku', notes:'Forum khusus bina konstruksi Prov Kalteng', color:"indigo", icon:'mdi-forum'},
      ]
    };
  },
  methods:{
    back(){
      this.$router.push("/home")
    },
    toRegistration(item) {
      this.$router.push({ path: '/register', query: { registration_type: item.id } });
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor:pointer ;
}
.v-card.on-hover.theme--dark {background-color: rgba(#FFFFFF, 0.8)}
.v-card__text {color: #000}
</style>